/*
 * @Description:
 * @Version: 2.0
 * @Author: lpc3914@163.com
 * @Date: 2023-05-05 16:47:41
 * @LastEditors: lpc
 * @LastEditTime: 2023-05-05 16:50:36
 */
const usersRouter = {
  route: null,
  name: null,
  title: '微信用户管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/users/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '微信用户列表',
      type: 'view',
      name: 'InputerCreate',
      route: '/users/list',
      filePath: 'view/users/users-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
    },
  ],
}

export default usersRouter
