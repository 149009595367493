/*
 * @Description:
 * @Version: 2.0
 * @Author: lpc3914@163.com
 * @Date: 2023-06-13 18:05:24
 * @LastEditors: lpc
 * @LastEditTime: 2023-06-15 13:24:32
 */
const webwxRouter = {
  route: null,
  name: null,
  title: '页面管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/webwx/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '页面列表',
      type: 'tab',
      name: 'ExcelFormList',
      route: '/webwx/list',
      filePath: 'view/webwx',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      children: [
        {
          title: '页面列表',
          type: 'view',
          name: 'ExcelFormList',
          route: '/webwx/list',
          filePath: 'view/webwx/webwx.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
        },
        {
          title: '添加页面资料',
          type: 'view',
          name: 'FormCreate',
          route: '/webwx/add',
          filePath: 'view/webwx/webwxedit.vue',
          inNav: true,
          icon: 'iconfont icon-add',
        },
      ],
    },
  ],
}

export default webwxRouter
