/*
 * @Description:
 * @Version: 2.0
 * @Author: lpc3914@163.com
 * @Date: 2023-03-28 17:00:17
 * @LastEditors: lpc
 * @LastEditTime: 2023-06-16 17:35:01
 */
import homeRouter from './home-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/about',
    component: () => import('@/view/home/home'),
    children: [...homeRouter],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/view/login/login'),
  },
  {
    path: '/wxindex',
    name: 'wxindex',
    component: () => import('@/view/wxindex/wxindex'),
    meta: {
      title: '票村',
      keepAlive: true, // 需要被缓存
    },
  },
  {
    redirect: '/404',
    path: '/:pathMatch(.*)',
  },
]

export default routes
