const excelformRouter = {
  /*
   * @Descripttion:
   * @@version: v.001
   * @@Company: SCB
   * @@Author: lpc3914
   * @Date: 2023-04-07 15:56:06
   * @LastEditors: lpc
   * @LastEditTime: 2023-06-15 00:19:46
   */
  route: null,
  name: null,
  title: '表单资料管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/excelform/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '表单资料列表',
      type: 'tab',
      name: 'ExcelFormList',
      route: '/excelform/list',
      filePath: 'view/excelform',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      children: [
        {
          title: '表单资料列表',
          type: 'view',
          name: 'ExcelFormList',
          route: '/excelform/list',
          filePath: 'view/excelform/excelform-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
        },
        {
          title: '添加表单资料',
          type: 'view',
          name: 'FormCreate',
          route: '/excelform/add',
          filePath: 'view/excelform/excelforme.vue',
          inNav: true,
          icon: 'iconfont icon-add',
        },
      ],
    },

    {
      title: '身份表单记录',
      type: 'view',
      name: 'FormsCreate',
      route: '/formslog/list',
      filePath: 'view/formslog/formslog.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
    },
    {
      title: '表单资料列表-表单',
      type: 'view',
      name: 'FormsList',
      route: '/excelform/forms',
      filePath: 'view/excelform/forms.vue',
      inNav: false,
      icon: 'iconfont icon-add',
    },
    {
      title: '修改表单',
      type: 'view',
      name: 'FormsList',
      route: '/excelform/form',
      filePath: 'view/excelform/formsedit.vue',
      inNav: false,
      icon: 'iconfont icon-add',
    },
  ],
}

export default excelformRouter
